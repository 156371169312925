<template>
  <div class="database">
    <div class="searchBox">
      <el-input
        :placeholder="i18nData.placehold"
        v-model="search"
        class="input-search"
        @keyup.enter.native="goSearch"
      >
        <el-button
          class="button-search"
          slot="append"
          icon="el-icon-search"
          @click="goSearch"
        ></el-button>
      </el-input>
    </div>
    <div class="databaseBox">
        <div class="searchNone" v-show="isnone">
          <img src="./../../public/img/search/search_none.png" alt="" />
          <span>{{i18nData.nonetips}}</span>
        </div>
        <div class="interest" v-show="isnone">
          <div>{{i18nData.interest}}</div>
          <div></div>
        </div>
      <databaseList :archives-list="list"></databaseList>
    </div>
    <div class="pagination" v-if="archivesList.length > 0">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="currentPage"
        :page-size="pageSize"
        layout="prev, pager, next, jumper"
        :total="total"
        background
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import databaseList from "./../components/databaseList.vue";
import { toTop } from "./../common/top.mixin.js";

export default {
  name: "search",
  mixins: [toTop],
  components: {
    databaseList
  },
  data() {
    return {
      search: "",
      total: 100,
      pageSize: 10,
      currentPage: 1,
      archivesList: [],
      channel: {},
      insterestList: [], //最感兴趣
      isnone:false
    };
  },
  computed: {
    list() {
      if (this.archivesList.length > 0) {
        return this.archivesList;
      }
      return this.insterestList;
    }
  },
  created() {
    this.$store.state.activeIndex = "";
    let search = this.$route.query.search;
    if (search) {
      this.search = search;
    }
    this.goSearch();
  },
  methods: {
    // 分页处理
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(val);
      this.currentPage = val;
      this.goSearch();
      this.backTop();
    },
    async goSearch() {
      // 这里用try catch包裹，请求失败的时候就执行catch里的
      try {
        let params = {
          page: this.currentPage,
          orderby: "default",
          orderway: "asc",
          q: this.search
        };
        let res = await this.$api.https.search(params);
        console.log(res);
        if (!res.code) {
          this.$message({
            message: res.msg
          });
          return;
        }

        let { pageList, channel } = res.data;
        this.channel = channel;
        this.total = pageList.total;
        this.archivesList = [...pageList.data];
        if (!(this.archivesList.length > 0)) {
          this.getInsterest();
          this.isnone = true;
        }else{
          this.isnone = false;
        }
      } catch (err) {
        console.log(err);
      }
    },
    async getInsterest() {
      // 这里用try catch包裹，请求失败的时候就执行catch里的
      try {
        let params = {
          page: 1,
          model: 2,
          orderby: "view"
        };
        let res = await this.$api.https.getArchives(params);
        console.log(res);
        if (!res.code) {
          this.$message({
            message: res.msg
          });
          return;
        }

        let { pageList } = res.data;
        this.insterestList = [...pageList.data];
      } catch (err) {
        console.log(err);
      }
    }
  }
};
</script>
<style lang="scss" scoped>
@mixin interest($width) {
  .interest {
    text-align: center;
    height: 40px;
    width: $width;
    font-size: 32px;
    div:nth-child(2) {
      background: #cbe8ff;
      width: $width;
      height: 11px;
      border-radius: 5px;
      margin: -5px 0 0 0;
    }
  }
}
.database {
  background-color: #ffffff;
  line-height: 24px;
  text-align: left;
  .searchBox {
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #d2d2d2;
    .input-search {
      width: 487px;
      height: 30px;
      border-radius: 5px;
    }
    .button-search {
      background-color: #fff;
    }
  }
  .databaseBox {
    padding: 0 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .searchNone {
      margin: 20px 0 0 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      border-bottom: 1px solid #dcdfe6;
      img {
        width: 20vh;
        height: 20vh;
      }
      span {
        color: #a1a1a1;
        font-size: 14px;
      }
    }
    .interest {
      margin: 40px 0 10px;
    }
    @include interest(400px);
  }
}
</style>
